




































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutSideBar from '@/layouts/LayoutSideBar.vue'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import MembershipInformationService from '@/services/MembershipInformationService'
import { Member } from '@/models/Member'
import CheckDevice from '../helpers/CheckDevice'
import moment from 'moment'
// @ts-ignore
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')

const dict = {
  custom: {
    lastName: {
      required: () => '「名」を入力してください。',
      regex: () => '「名」を数字/記号を除く全角文字で入力してください。'
    },
    firstName: {
      required: () => '「姓」を入力してください。',
      regex: () => '「姓」を数字/記号を除く全角文字で入力してください。'
    },
    lastNameKana: {
      required: () => '「めい」を入力してください。',
      regex: () => '「めい」を全てひらがなで入力してください。'
    },
    firstNameKana: {
      required: () => '「せい」を入力してください。',
      regex: () => '「せい」を全てひらがなで入力してください。'
    },
    email: {
      required: () => '「メールアドレス」を入力してください。',
      email: () => '「メールアドレス」を正しく入力してください。'
    },
    phoneNo: {
      regex: () => '電話番号は半角数字でなければなりません。',
      min: () => '「電話番号」を半角数字10~11桁で入力してください。',
      max: () => '「電話番号」を半角数字10~11桁で入力してください。',
      required: () => '携帯電話番号が無効です。'
    },
    birthday: {
      required: () => '「生年月日」を指定してください。'
    },
    city: {
      required: () => '「市区町村」を入力してください。'
    },
    prefectures: {
      required: () => '「都道府県」を入力してください。'
    },
    address: {
      required: () => '「以降の住所」を入力してください。'
    },
    zipCode: {
      required: () => '「郵便番号」を半角数字7桁で入力してください。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    LayoutSideBar,
    ValidationProvider,
    ValidationObserver,
    DatePicker
  }
})
export default class MembershipInformation extends Vue {
  public patternNumber: string = `^(\\d)+$`
  public msg: boolean = false
  public maxDayCurrent: any = moment(new Date()).format('yyyy-MM-DD')
  public isErrorLastName: boolean = false
  public isErrorFirstName: boolean = false
  public isErrorLastNameKana: boolean = false
  public isErrorFirstNameKana: boolean = false
  public isErrorEmail: boolean = false
  public isErrorEmailConfirmation: boolean = false
  public isErrorPassword: boolean = false
  public isErrorPasswordConfirmation: boolean = false
  public isErrorPhoneNo: boolean = false
  public isErrorBirthday: boolean = false
  public errorAll: boolean = true
  public isMobile: boolean = false
  @Auth.Action
  public signOut!: () => void

  private member = new Member()
  private today: any = moment(new Date()).format('yyyy-MM-DD')
  private zipcodeBeforeChange: any
  private zipcodeAffterChange: any
  private zipcodeChange: boolean = false

  @Watch('member.zip_code')
  watchZipcode() {
    this.zipcodeAffterChange = this.member.zip_code
    if (this.zipcodeBeforeChange !== this.zipcodeAffterChange) {
      this.zipcodeChange = true
    }
  }

  @Watch('member.birthday')
  watchBirtday() {
    let birthday = moment(this.member.birthday).format('yyyy-MM-DD')
    if (birthday > this.today) {
      // eslint-disable-next-line no-extra-semi
      ;(this.$refs['modal-check-birthday'] as any).show()
    }
  }

  created() {
    if (CheckDevice.isMobile()) {
      this.isMobile = true
    }
    if (localStorage.getItem('member')) {
      this.member = localStorage.getItem('member')
        ? JSON.parse(localStorage.getItem('member') || '')
        : []
    } else {
      MembershipInformationService.getMember().then(
        (response: any) => {
          if (response.data) {
            let items = response.data.items
            this.member = items.profile
            this.member.email = items.email
            this.member.birthday = moment(String(this.member.birthday)).format(
              'yyyy-MM-DD'
            )
            this.zipcodeBeforeChange = this.member.zip_code
          }
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        }
      )
      localStorage.removeItem('zipcode_change')
    }
  }

  public handleUpdateProfile() {
    localStorage.setItem('member', JSON.stringify(this.member))
    if (this.zipcodeChange == true) {
      localStorage.setItem('zipcode_change', 'true')
    }
    this.$router.push({
      name: 'confirm-membership-information'
    })
  }

  public addressReflected() {
    MembershipInformationService.addressReflected(this.member.zip_code).then(
      (response: any) => {
        if (response.data) {
          let items = response.data.items
          this.member.city = items.city
          this.member.address = items.address
          this.member.prefecture = items.prefecture
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status) {
          this.member.city = ''
          this.member.address = ''
          this.member.prefecture = ''
        }
      }
    )
  }

  public deleteMember() {
    this.$router.push({ name: 'withdraw-procedure' })
  }

  public changePassword() {
    this.$router.push({ name: 'change-password' })
  }

  public confirmCreditCard() {
    this.$router.push({ name: 'credit-card-verification' })
  }

  public setBirthdayDate() {
    let birthday = moment(this.member.birthday).format('yyyy-MM-DD')
    if (birthday > this.today) {
      this.member.birthday = this.today
      ;(this.$refs['modal-check-birthday'] as any).hide()
    }
  }

  focusOutLastName() {
    this.isErrorLastName = true
  }

  focusInLastName() {
    this.isErrorLastName = false
    this.errorAll = false
  }

  focusOutFirstName() {
    this.isErrorFirstName = true
  }

  focusInFirstName() {
    this.isErrorFirstName = false
    this.errorAll = false
  }

  focusOutLastNameKana() {
    this.isErrorLastNameKana = true
  }

  focusInLastNameKana() {
    this.isErrorLastNameKana = false
    this.errorAll = false
  }

  focusOutFirstNameKana() {
    this.isErrorFirstNameKana = true
  }

  focusInFirstNameKana() {
    this.isErrorFirstNameKana = false
    this.errorAll = false
  }

  focusOutEmail() {
    this.isErrorEmail = true
  }

  focusInEmail() {
    this.isErrorEmail = false
    this.errorAll = false
  }

  focusOutPhoneNo() {
    this.isErrorPhoneNo = true
  }

  focusInPhoneNo() {
    this.isErrorPhoneNo = false
    this.errorAll = false
  }

  focusOutBirthday() {
    this.isErrorBirthday = true
  }

  focusInBirthday() {
    this.isErrorBirthday = false
    this.errorAll = false
  }

  notAfterToday(date: any) {
    return date > new Date(new Date())
  }
}
